<template>
  <app-bar
    title="Pengaturan"
    :previousLink="previousLink"
    :noBackButtonOnDesktop="true"
    :noDivider="true"
    class="sub" />

  <nav class="menu mt-3">
    <router-link
      :to="{ name: 'SettingPersonalData' }"
      class="p-3 border rounded d-flex align-items-center mb-3 item">
      <i class="ri-user-line"></i>
      Data Diri

      <i class="ri-arrow-drop-right-line arrow-right"></i>
    </router-link>

    <router-link
      :to="{ name: 'SettingContact' }"
      class="p-3 border rounded d-flex align-items-center mb-3 item">
      <i class="ri-phone-line"></i>
      Kontak

      <i class="ri-arrow-drop-right-line arrow-right"></i>
    </router-link>

    <router-link
      :to="{ name: 'SettingPassword' }"
      class="p-3 border rounded d-flex align-items-center mb-3 item">
      <i class="ri-lock-password-line"></i>
      Kata Sandi

      <i class="ri-arrow-drop-right-line arrow-right"></i>
    </router-link>
  </nav>
</template>

<script>
import AppBar from '@/components/AppBar.vue'
import { nextTick } from 'vue'
import { title } from '@/core/page'

export default {
  components: { AppBar },
  setup () {
    const previousLink = {
      name: window.innerWidth > 520 ? 'ProfilOrder' : 'Profil'
    }

    nextTick(() => {
      title.value = 'Pengaturan'
    })

    return {
      previousLink
    }
  }
}
</script>
